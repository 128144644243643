import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import ContactForm from '../components/ContactForm';
import contact from '../assets/images/contact.jpeg';

const Contact = props => {
  let plan = '';
  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);
    plan = urlParams.get('plan');
  }

  return (
    <Layout>
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="Contact" />
      </Helmet>

      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            <header className="major">
              <h1>Contact Me</h1>
            </header>
            <span className="image main">
              <img src={contact} alt="Contact Me" />
            </span>
          </div>
        </section>
        <ContactForm plan={plan} />
      </div>
    </Layout>
  );
};

export default Contact;
