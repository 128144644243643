import React, { useState } from 'react';

const ContactForm = props => {
  const [values, setValues] = useState({
    name: '',
    email: '',
    phone: '',
    plan: props.plan ? props.plan : '',
    level: '',
    goals: '',
    scale: '',
    message: '',
    isOnFitnessPlan: false,
  });
  const [errors, setErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [requestSent, setRequestSent] = useState(false);

  const validate = newValues => {
    const newErrors = {};
    const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!newValues.name) {
      newErrors.name = true;
    }

    if (!newValues.email) {
      newErrors.email = true;
    }
    if (!emailReg.test(newValues.email)) {
      newErrors.email = true;
    }

    if (!newValues.phone) {
      newErrors.phone = true;
    }

    if (!newValues.goals) {
      newErrors.goals = true;
    }

    if (!newValues.plan) {
      newErrors.plan = true;
    }

    return newErrors;
  };

  const submitMessage = e => {
    e.preventDefault();
    setErrors(validate(values));
    setShowErrors(true);

    const url =
      'https://www.karmentownetraining.com/.netlify/functions/sendgrid';
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(values),
    };

    fetch(url, options).then(response => {
      setRequestSent(true);
    });
  };

  const onFieldChange = (prop, value) => {
    const newValues = { ...values, [prop]: value };
    const newErrors = validate(newValues);

    setErrors(newErrors);
    setValues(newValues);
  };

  if (requestSent) {
    return (
      <div className="inner">
        <h3>Thank you for your request!</h3>
        <br />
        <p>Soon, I will be reaching out to you to discuss!</p>
      </div>
    );
  }

  return (
    <div className="inner">
      <p>Please fill out the form below and I'll reach out to you!</p>
      <form method="post" action="#">
        <div className="field half first">
          <label htmlFor="name">Name*</label>
          <input
            className={showErrors && errors.name ? 'error' : ''}
            type="text"
            name="name"
            id="name"
            value={values.name}
            onChange={e => onFieldChange('name', e.target.value)}
          />
        </div>
        <div className="field half">
          <label htmlFor="email">Email*</label>
          <input
            className={showErrors && errors.email ? 'error' : ''}
            type="text"
            name="email"
            id="email"
            value={values.email}
            onChange={e => onFieldChange('email', e.target.value)}
          />
        </div>
        <div className="field">
          <label htmlFor="phone">Phone*</label>
          <input
            className={showErrors && errors.phone ? 'error' : ''}
            type="text"
            name="phone"
            id="phone"
            value={values.phone}
            onChange={e => onFieldChange('phone', e.target.value)}
          />
        </div>
        <div className="field first half">
          <label htmlFor="plan">Plan*</label>
          <select
            className={showErrors && errors.plan ? 'error' : ''}
            type="text"
            name="plan"
            id="plan"
            value={values.plan}
            onChange={e => onFieldChange('plan', e.target.value)}
          >
            <option value=""></option>
            <option value="Personal">1 on 1 Personal Training</option>
            <option value="Virtual">Virtual Training</option>
            <option value="Group">Partner/Small Group Training</option>
          </select>
        </div>
        <div className="field half">
          <label htmlFor="plan">Level</label>
          <select
            type="text"
            name="level"
            id="level"
            value={values.level}
            onChange={e => onFieldChange('level', e.target.value)}
          >
            <option value=""></option>
            <option value="Gold">Gold</option>
            <option value="Silver">Silver</option>
            <option value="Platinum">Platinum</option>
          </select>
        </div>
        <div className="field">
          <label htmlFor="scale">
            On A Scale of 1-10, How Ready Are You To Change?
          </label>
          <select
            type="text"
            name="scale"
            id="scale"
            value={values.scale}
            onChange={e => onFieldChange('scale', e.target.value)}
          >
            <option value=""></option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
        </div>
        <div className="field">
          <label htmlFor="goals">What Are Your Fitness Goals?*</label>
          <textarea
            className={showErrors && errors.goals ? 'error' : ''}
            name="goals"
            id="goals"
            rows="6"
            value={values.goals}
            onChange={e => onFieldChange('goals', e.target.value)}
          ></textarea>
        </div>
        <div className="field">
          <label htmlFor="message">Message</label>
          <textarea
            name="message"
            id="message"
            rows="6"
            value={values.message}
            onChange={e => onFieldChange('message', e.target.value)}
          ></textarea>
        </div>
        <div className="field">
          <input
            type="radio"
            id="isOnFitnessPlan"
            name="isOnFitnessPlan"
            checked={values.isOnFitnessPlan}
            onClick={e =>
              onFieldChange('isOnFitnessPlan', !values.isOnFitnessPlan)
            }
          />
          <label htmlFor="isOnFitnessPlan">
            Are You Currently On A Fitness Plan?
          </label>
        </div>
        <ul className="actions">
          <li>
            <input
              type="submit"
              value="Send Message"
              className="special"
              onClick={submitMessage}
            />
          </li>
        </ul>
      </form>
    </div>
  );
};
export default ContactForm;
